import React from 'react';

const Section2 = () => {
  return (
    <div className="bg-white h-screen flex items-center justify-center">
      <div className="container mx-auto text-center">
        <p className="w-full text-5xl sm:text-6xl md:text-7xl lg:text-7xl font-bold">
          Studio de production audiovisuelle basé sur Grenoble
        </p>
        
      </div>
    </div>
  );
};

export default Section2;