import React from 'react';
import ReactPlayer from 'react-player';

const Section1 = () => {
    return (
      <div className="relative h-screen bg-black">
        <video className="absolute inset-0 w-full h-full object-cover" autoPlay loop muted>
          <source src="/video_VS.mp4" type="video/mp4" />
        </video>
        <div className="relative z-10 flex items-center justify-center h-full">
          <img src="/logo_VS_blanc.png" alt="Logo 1" className="w-40 lg:w-56 h-auto" />
        </div>
      </div>
    );
  };
  
export default Section1;


{/*
<div className="relative h-screen w-screen overflow-hidden">
<video className="absolute inset-0 w-full h-full object-cover" autoPlay loop muted>
  <source src="/video_VLT.mp4" type="video/mp4" />
</video>
<div className="relative z-10 flex items-center justify-center">
  <img src="/logo_VS.png" alt="Logo 1" className="w-32 h-10 md:w-15 md:h-15" />
</div>
</div>
*/}