import React from 'react';

const Component = ({ videoSrc, logoSrc, text, lien}) => {
    return (
      <div className="relative bg-gray-100 p-6 rounded-lg mb-6 overflow-hidden w-auto h-160 md:h-144 lg:h-128">
        <div className="absolute inset-0">
          <video className="absolute inset-0 w-full h-full object-cover" autoPlay loop muted playsInline>
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full">
          <img src={logoSrc} alt="Logo" className="mb-0 h-auto w-96 sm:w-96 md:w-128 lg:w-128" />
          <p className="text-center text-white mb-3 text-base md:text-lg lg:text-2xl">{text}</p>
          <a     href={lien}
          target="_blank"
          rel="noopener noreferrer" className="bg-white-500 text-white text-xs md:text-base border py-2 px-4 rounded hover:bg-gray-200 hover:text-black">
        En savoir plus
      </a>
        </div>
      </div>
    );
  };
  


const Section3 = () => {
  return (
    <div className="bg-gray-200 py-20">
      <div className="container mx-auto grid grid-cols-1 gap-12 px-4 md:px-8 lg:px-16">
        <Component videoSrc="/video_VLH.mp4" logoSrc="/logo_VLH.png" text="production de vidéo d'entreprise" lien="https://www.voici-l-horizon.com"/>
        <Component videoSrc="/video_VLS.mp4" logoSrc="/logo_VLS.png" text="production de films de mariage" lien="https://www.voici-le-soleil.com"/>
        <Component videoSrc="/video_VLT.mp4" logoSrc="/logo_VLT.png" text="production de vidéo liée à la musique, à l'art et aux festivités" lien="https://www.voici-la-tempete.com"/>
      </div>
    </div>
  );
};

export default Section3;